import {fork} from 'redux-saga/effects';
import keycloakAuth from './keycloakAuth';
import applicationWatcher from './application/applicationWatcher';
import userProfileWatcher from './user-profiles/userProfileWatcher';
import serviceProviderWatcher from './service-providers/serviceProviderWatcher';
import commissionerWatcher from './commissioners/commissionerWatcher';
import contractPartnerWatcher from './contract-partners/contractPartnerWatcher';
import inaRulesWatcher from './ina-rules/inaRulesWatcher';
import arcGISAuth from './arcGISAuth';
import feesAndEstimationWatcher from './fees-and-estimation/feesAndEstimationWatcher';

// application sagas
export default [
    fork(applicationWatcher),
    fork(keycloakAuth.watcher),
    fork(arcGISAuth.watcher),
    fork(userProfileWatcher),
    fork(serviceProviderWatcher),
    fork(commissionerWatcher),
    fork(contractPartnerWatcher),
    fork(inaRulesWatcher),
    fork(feesAndEstimationWatcher),
];
