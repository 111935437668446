import {eupUserRoleTypes, clientTypes} from '@ace-de/eua-entity-types';
import {acFeatureActions, acFeatures} from './acFeatures';
import routePaths from '../routePaths';

const acAuthorizationRoles = {
    [eupUserRoleTypes.SUPER_ADMIN]: {
        name: eupUserRoleTypes.SUPER_ADMIN,
        features: {
            [acFeatureActions.CREATE]: [
                acFeatures.USER_ACCOUNT,
                acFeatures.COMMISSIONERS,
            ],
            [acFeatureActions.READ]: [
                acFeatures.USER_ACCOUNT,
                acFeatures.USER_ACTIVE_STATUS,
                acFeatures.SERVICE_PROVIDERS,
                acFeatures.COMMISSIONERS,
                acFeatures.CONTRACT_PARTNERS,
                acFeatures.ECS_TRANSLATIONS,
                acFeatures.VPM_TRANSLATIONS,
                acFeatures.AC_TRANSLATIONS,
                acFeatures.LEA_TRANSLATIONS,
                acFeatures.INA_RULES,
                acFeatures.FEES_AND_ESTIMATION,
            ],
            [acFeatureActions.UPDATE]: [
                acFeatures.USER_BASIC_DATA,
                acFeatures.USER_ACTIVE_STATUS,
                acFeatures.ECS_SERVICE_CASES,
                acFeatures.USER_ROLES_AC_CLIENT,
                acFeatures.USER_ROLES_ECS_CLIENT,
                acFeatures.USER_ROLES_VPM_CLIENT,
                acFeatures.USER_ROLES_LEA_CLIENT,
                acFeatures.SERVICE_PROVIDERS,
                acFeatures.COMMISSIONERS,
                acFeatures.COMMISSIONER_ACTIVE_STATUS,
                acFeatures.CONTRACT_PARTNERS,
                acFeatures.SP_ACTIVE_STATUS,
                acFeatures.ECS_TRANSLATIONS,
                acFeatures.VPM_TRANSLATIONS,
                acFeatures.AC_TRANSLATIONS,
                acFeatures.LEA_TRANSLATIONS,
                acFeatures.STANDBY_TIMES,
                acFeatures.INA_RULES,
                acFeatures.FEES_AND_ESTIMATION,
            ],
            [acFeatureActions.DELETE]: [],
        },
        systems: [clientTypes.AC_CLIENT],
        forbiddenRoutes: [],
        allowedRoutes: [],
        layouts: {},
    },
    [eupUserRoleTypes.ECS_ADMIN]: {
        name: eupUserRoleTypes.ECS_ADMIN,
        features: {
            [acFeatureActions.CREATE]: [
                acFeatures.COMMISSIONERS,
                acFeatures.USER_ACCOUNT,
            ],
            [acFeatureActions.READ]: [
                acFeatures.USER_ACCOUNT,
                acFeatures.SERVICE_PROVIDERS,
                acFeatures.COMMISSIONERS,
                acFeatures.CONTRACT_PARTNERS,
                acFeatures.ECS_TRANSLATIONS,
            ],
            [acFeatureActions.UPDATE]: [
                acFeatures.USER_ROLES_ECS_CLIENT,
                acFeatures.ECS_SERVICE_CASES,
                acFeatures.SERVICE_PROVIDERS,
                acFeatures.COMMISSIONERS,
                acFeatures.COMMISSIONER_ACTIVE_STATUS,
                acFeatures.CONTRACT_PARTNERS,
                acFeatures.SP_ACTIVE_STATUS,
                acFeatures.ECS_TRANSLATIONS,
                acFeatures.STANDBY_TIMES,
            ],
            [acFeatureActions.DELETE]: [],
        },
        systems: [clientTypes.AC_CLIENT],
        forbiddenRoutes: [routePaths.INA_RULES],
        allowedRoutes: [],
        layouts: {},
    },
    [eupUserRoleTypes.VPM_ADMIN]: {
        name: eupUserRoleTypes.VPM_ADMIN,
        features: {
            [acFeatureActions.CREATE]: [
                acFeatures.USER_ACCOUNT,
            ],
            [acFeatureActions.READ]: [
                acFeatures.USER_ACCOUNT,
                acFeatures.VPM_TRANSLATIONS,
            ],
            [acFeatureActions.UPDATE]: [
                acFeatures.USER_ROLES_VPM_CLIENT,
                acFeatures.VPM_TRANSLATIONS,
            ],
            [acFeatureActions.DELETE]: [],
        },
        systems: [clientTypes.AC_CLIENT],
        forbiddenRoutes: [
            routePaths.SERVICE_PROVIDER,
            routePaths.SERVICE_PROVIDER_SEARCH,
            routePaths.COMMISSIONER_SEARCH,
            routePaths.COMMISSIONER,
            routePaths.STANDBY_TIMES,
            routePaths.INA_RULES,
        ],
        allowedRoutes: [],
        layouts: {},
    },
    [eupUserRoleTypes.LEA_ADMIN]: {
        name: eupUserRoleTypes.LEA_ADMIN,
        features: {
            [acFeatureActions.CREATE]: [
                acFeatures.USER_ACCOUNT,
            ],
            [acFeatureActions.READ]: [
                acFeatures.USER_ACCOUNT,
                acFeatures.LEA_TRANSLATIONS,
                acFeatures.INA_RULES,
                acFeatures.FEES_AND_ESTIMATION,
            ],
            [acFeatureActions.UPDATE]: [
                acFeatures.USER_ROLES_LEA_CLIENT,
                acFeatures.LEA_TRANSLATIONS,
                acFeatures.INA_RULES,
                acFeatures.FEES_AND_ESTIMATION,
            ],
            [acFeatureActions.DELETE]: [],
        },
        systems: [clientTypes.AC_CLIENT],
        forbiddenRoutes: [
            routePaths.SERVICE_PROVIDER,
            routePaths.SERVICE_PROVIDER_SEARCH,
            routePaths.COMMISSIONER_SEARCH,
            routePaths.COMMISSIONER,
            routePaths.STANDBY_TIMES,
        ],
        allowedRoutes: [],
        layouts: {},
    },
};

export default acAuthorizationRoles;
