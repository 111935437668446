import {renderRoutes} from '@computerrock/formation-router';
import routePaths from './routePaths';
import keycloakAuth from './keycloakAuth';
import arcGISAuth from './arcGISAuth';
import * as applicationLoaderSagas from './application/applicationLoaderSagas';
import * as userProfileLoaderSagas from './user-profiles/userProfileLoaderSagas';
import * as serviceProviderLoaderSagas from './service-providers/serviceProviderLoaderSagas';
import * as commissionerLoaderSagas from './commissioners/commissionerLoaderSagas';
import * as contractPartnerLoaderSagas from './contract-partners/contractPartnerLoaderSagas';
import * as inaRulesLoaderSagas from './ina-rules/inaRulesLoaderSagas';
import ApplicationScreen from './application/ApplicationScreen';
import NotFoundScreen from './application/NotFoundScreen';
import AuthRedirectionScreen from './application/AuthRedirectionScreen';
import DashboardSectionScreen from './dashboard/DashboardSectionScreen';
import DashboardScreen from './dashboard/DashboardScreen';
import ServiceProviderSearchScreen from './service-providers/ServiceProviderSearchScreen';
import ServiceProviderScreen from './service-providers/ServiceProviderScreen';
import CommissionerSearchScreen from './commissioners/CommissionerSearchScreen';
import CommissionerScreen from './commissioners/CommissionerScreen';
import UserSearchScreen from './user-profiles/UserSearchScreen';
import StandbyTimesScreen from './service-providers/StandbyTimesScreen';
import AccessDeniedScreen from './application/AccessDeniedScreen';
import acAccessControl from './acAccessControl';
import TextManagementScreen from './text-management/TextManagementScreen';
import ContractPartnerSearchScreen from './contract-partners/ContractPartnerSearchScreen';
import ContractPartnerScreen from './contract-partners/ContractPartnerScreen';
import MaintenanceScreen from './application/MaintenanceScreen';
import INARulesScreen from './ina-rules/INARulesScreen';
import config from './config';
import FeesAndEstimationScreen from './fees-and-estimation/FeesAndEstimationScreen';
import * as feesAndEstimationLoaderSagas from './fees-and-estimation/feesAndEstimationLoaderSagas';

/**
 * Location object definition (react-router, history)
 *
 * @typedef {Object} Location
 * @property {string} key - key is available for all by HashHistory
 * @property {string} pathname - path part of route
 * @property {string} search - search query part of route
 * @property {string} hash - hash (#) part of route
 * @property {Object} state - user defined state for the route
 */

/**
 * ConnectedRoute object definition (react-router-config, connected-react-router, redux-saga)
 *
 * @typedef {Object} ConnectedRoute
 * @property {string} path - any valid URL path that path-to-regexp understands.
 * @property {?Object} component - React component
 * @property {?function} render - render prop function
 * @property {?Location} location - for matching against different location than one in history
 * @property {?boolean} exact - when true, will only match if the path matches the location.pathname exactly
 * @property {?boolean} string - when true, a path that has a trailing slash will only match a location.pathname
 *                      with a trailing slash
 * @property {?boolean} sensitive - when true, will match if the path is case sensitive.
 * @property {?Array<ConnectedRoute>} routes - sub-routes
 * @property {?Array<Array<[saga, Object]>>} locationChangeSideEffects - Redux sagas (side-effects) to be run
 *                                            with payload object
 */

/**
 * Static route configuration
 *
 * @returns {Array<ConnectedRoute>}
 */
const getRoutes = () => [{
    component: ApplicationScreen,
    locationChangeSideEffects: [],
    routes: [
        window.DYNAMIC_ENV.IS_MAINTENANCE_MODE_ON
        && window.location.hostname !== config.BACKDOOR_URL
            ? {
                path: '*',
                component: MaintenanceScreen,
            } : {
                path: routePaths.KEYCLOAK_AUTHORIZE,
                exact: true,
                component: AuthRedirectionScreen,
                locationChangeSideEffects: [
                    [keycloakAuth.authorize],
                ],
            },
        {
            path: routePaths.KEYCLOAK_AUTHENTICATE,
            exact: true,
            component: AuthRedirectionScreen,
            locationChangeSideEffects: [
                [keycloakAuth.authenticate],
            ],
        },
        {
            path: routePaths.ARCGIS_AUTHORIZE,
            exact: true,
            component: AuthRedirectionScreen,
            locationChangeSideEffects: [
                [arcGISAuth.authorize],
            ],
        },
        {
            path: routePaths.ARCGIS_AUTHENTICATE,
            exact: true,
            component: AuthRedirectionScreen,
            locationChangeSideEffects: [
                [arcGISAuth.authenticate],
            ],
        },
        {
            path: routePaths.SYSTEM_ACCESS_DENIED,
            exact: true,
            component: AccessDeniedScreen,
            locationChangeSideEffects: [
                [keycloakAuth.loadAuthSession],
            ],
        },
        {
            component: ({route}) => renderRoutes(route.routes),
            locationChangeSideEffects: [
                [applicationLoaderSagas.ensureRoute],
                [keycloakAuth.loadAuthSession],
                [userProfileLoaderSagas.loadUserProfile],
                [acAccessControl.authenticateSystemAccess],
                [arcGISAuth.loadAuthSession],
                [userProfileLoaderSagas.loadUserProfiles],
                [acAccessControl.authenticateRouteAccess],
            ],
            routes: [
                {
                    path: routePaths.ROOT,
                    exact: true,
                    component: () => null,
                    locationChangeSideEffects: [],
                },
                {
                    path: routePaths.ROUTE_ACCESS_DENIED,
                    exact: true,
                    component: AccessDeniedScreen,
                    locationChangeSideEffects: [],
                },
                {
                    path: routePaths.DASHBOARD_SECTION,
                    component: DashboardSectionScreen,
                    locationChangeSideEffects: [],
                    routes: [
                        {
                            path: routePaths.DASHBOARD,
                            exact: true,
                            component: DashboardScreen,
                            locationChangeSideEffects: [],
                        },
                        {
                            path: routePaths.USER_MANAGEMENT,
                            exact: true,
                            component: UserSearchScreen,
                            locationChangeSideEffects: [
                                [userProfileLoaderSagas.loadUserSearchResults],
                            ],
                        },
                        {
                            path: routePaths.SERVICE_PROVIDER_SEARCH,
                            exact: true,
                            component: ServiceProviderSearchScreen,
                            locationChangeSideEffects: [
                                [serviceProviderLoaderSagas.loadServiceProviderSearchResults],
                                [serviceProviderLoaderSagas.loadEmergencyLawyers],
                            ],
                        },
                        {
                            path: routePaths.COMMISSIONER_SEARCH,
                            exact: true,
                            component: CommissionerSearchScreen,
                            locationChangeSideEffects: [
                                [commissionerLoaderSagas.loadCommissionerSearchResults],
                            ],
                        },
                        {
                            path: routePaths.CONTRACT_PARTNER_SEARCH,
                            exact: true,
                            component: ContractPartnerSearchScreen,
                            locationChangeSideEffects: [
                                [contractPartnerLoaderSagas.loadContractPartnerSearchResults],
                            ],
                        },
                        {
                            path: routePaths.TEXT_MANAGEMENT,
                            exact: true,
                            component: TextManagementScreen,
                            locationChangeSideEffects: [],
                        },
                        {
                            path: routePaths.INA_RULES,
                            exact: true,
                            component: INARulesScreen,
                            locationChangeSideEffects: [
                                [inaRulesLoaderSagas.loadINARules],
                            ],
                        },
                        {
                            path: routePaths.FEES_AND_ESTIMATION,
                            exact: true,
                            component: FeesAndEstimationScreen,
                            locationChangeSideEffects: [
                                [feesAndEstimationLoaderSagas.loadAssistanceFees],
                                [feesAndEstimationLoaderSagas.loadEstimations],
                            ],
                        },
                        {
                            path: '*',
                            component: NotFoundScreen,
                        },
                    ],
                },
                {
                    path: routePaths.SERVICE_PROVIDER,
                    exact: true,
                    component: ServiceProviderScreen,
                    locationChangeSideEffects: [
                        [serviceProviderLoaderSagas.loadServiceProvider],
                    ],
                },
                {
                    path: routePaths.COMMISSIONER,
                    exact: true,
                    component: CommissionerScreen,
                    locationChangeSideEffects: [
                        [commissionerLoaderSagas.loadCommissioner],
                    ],
                },
                {
                    path: routePaths.CONTRACT_PARTNER,
                    exact: true,
                    component: ContractPartnerScreen,
                    locationChangeSideEffects: [
                        [contractPartnerLoaderSagas.loadContractPartner],
                        [contractPartnerLoaderSagas.loadContractPartnerTemporaryRestrictions],
                        [contractPartnerLoaderSagas.loadACECommissioners],
                        [contractPartnerLoaderSagas.loadContractPartnerServiceCaseSearchResults],
                    ],
                },
                {
                    path: routePaths.STANDBY_TIMES,
                    exact: true,
                    component: StandbyTimesScreen,
                    locationChangeSideEffects: [
                        [serviceProviderLoaderSagas.loadEmergencyLawyers],
                    ],
                },
            ],
        },
        {
            path: '*',
            component: NotFoundScreen,
        },
    ],
}];

export default getRoutes;
