import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {renderRoutes} from '@computerrock/formation-router';
import {AppLayoutMain, NavigationBar} from '@ace-de/ui-components/app-layout';
import NavigationBarContent from './ui-elements/NavigationBarContent';

const DashboardSectionScreen = ({route, isFeatureListSet}) => {
    if (!isFeatureListSet) return null;

    return (
        <Fragment>
            <NavigationBar>
                <NavigationBarContent />
            </NavigationBar>
            <AppLayoutMain>
                {renderRoutes(route.routes)}
            </AppLayoutMain>
        </Fragment>
    );
};

DashboardSectionScreen.propTypes = {
    route: PropTypes.object.isRequired,
    isFeatureListSet: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
    return {
        isFeatureListSet: state.acSystemAccessReducer.isFeatureListSet,
    };
};

export default connect(mapStateToProps, null)(DashboardSectionScreen);
