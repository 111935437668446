/**
 * @typedef {string} tabId
 */

/**
 * Dashboard screen tabs
 *
 * @enum {tabId}
 */
export default {
    USER_MANAGEMENT: 'user-management',
    SERVICE_PROVIDERS: 'service-providers',
    COMMISSIONERS: 'commissioners',
    CONTRACT_PARTNERS: 'contract-partners',
    TEXT_MANAGEMENT: 'text-management',
    INA_RULES: 'ina-rules',
    FEES_AND_ESTIMATION: 'fees-and-estimation',
};
